var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('div',{staticClass:"d-flex align-items-center mb-1 mr-1"},[_c('span',{staticClass:"title mr-1"},[_vm._v(" Date: ")]),_c('flat-pickr',{staticClass:"form-control invoice-edit-input",on:{"input":_vm.getOrders},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Recherche")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Recherche","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)]),_c('b-form-group',[_c('b-button',{staticClass:"ml-1 d-inline-block",attrs:{"variant":"gradient-primary","disabled":_vm.selectedOrders.selectedRows.length ===0},on:{"click":_vm.addPickup}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"ml-1"},[_vm._v(" Enregistrer ")])],1)],1)],1),(_vm.isLoading === true)?_c('div',{staticClass:"text-center mb-2"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1):_vm._e(),_c('vue-good-table',{ref:"my-table",attrs:{"columns":_vm.columns,"rows":_vm.orders,"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm,
    },"select-options":{
      enabled: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},on:{"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Affichage 1 à ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['40', '50', '100']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.total)+" résultats trouvés")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }